<template>
  <div id="app">
    <img
        alt="Signly logo"
        height="80"
        width="200"
        src="https://signly.blob.core.windows.net/images/signly-new-logo-inline-yellow-original.svg"
    >

    <div class="container">
      <h1>SCAN QR CODE</h1>

      <p v-if="errorMessage" class="alert">{{ errorMessage }}</p>

      <p v-if="decodedContent" class="content" :class="contentClass">{{ decodedContent }}</p>

      <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'App',
  components: {
    QrcodeStream
  },
  data() {
    return {
      decodedContent: null,
      errorMessage: null,
      hasNewContent: false
    }
  },
  computed: {
    contentClass () {
      return ['content', this.hasNewContent ? 'new' : null]
    }
  },
  methods: {
    onDecode(content) {
      this.decodedContent = content
      this.hasNewContent = true

      setTimeout(() => {
        this.hasNewContent =false
      }, 3000)
    },
    onInit(promise) {
      promise.then(() => {
        console.log('Successfully initialized! Ready for scanning now!')
      })
        .catch(error => {
          if (error.name === 'NotAllowedError') {
            this.errorMessage = 'Hey! I need access to your camera'
          } else if (error.name === 'NotFoundError') {
            this.errorMessage = 'Do you even have a camera on your device?'
          } else if (error.name === 'NotSupportedError') {
            this.errorMessage = 'Seems like this page is served in non-secure context (HTTPS, localhost or file://)'
          } else if (error.name === 'NotReadableError') {
            this.errorMessage = 'Couldn\'t access your camera. Is it already in use?'
          } else if (error.name === 'OverconstrainedError') {
            this.errorMessage = 'Constraints don\'t match any installed camera. Did you asked for the front camera although there is none?'
          } else {
            this.errorMessage = 'ERROR: ' + error.message
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

#app {
  text-align: center;

  h1 {
    margin: 0
  }

  .container {
    display: block;
    padding-left: 8%;
    padding-right: 8%;
  }

  .alert {
    border: rgba(red, .7) solid 1px;
    padding: 0.5rem;
    background-color: rgba(red, .2);
    color: red;
    text-align: left;
  }

  .content {
    border: 1px solid transparent;
    text-align: left;
    padding: 0.5rem;
    -webkit-transition: background-color 0.25s ease-out;
    -moz-transition: background-color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;

    &.new {
      background-color: rgba(#FECC00, .2);
      border: rgba(#FECC00, .5) solid 1px;

      -webkit-transition: background-color 0.15s ease-in;
      -moz-transition: background-color 0.15s ease-in;
      -o-transition: background-color 0.15s ease-in;
      transition: background-color 0.15s ease-in;
    }
  }
}
</style>
